@import 'styles/var.scss';
@media (prefers-color-scheme: light) {
  .logo-box {
    color: #242424;
  }
}

@media (prefers-color-scheme: dark) {
  .logo-box {
    color: #ffffff;
  }
}

.logo-box {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  text-decoration: none;

  .logo-text {
    font-size: 30px;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: bold;
  }

  .logo-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $mainColor;
    margin-left: 3px;
    margin-bottom: 5px;
  }
}
