@import "styles/var.scss";

.login-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-box {
    width: 400px;
    height: 420px;
    background-color: $mainColor;
    border-radius: 10px;
    padding: 30px 20px;
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .desc {
      opacity: 0.8;
      font-size: 1.2em;
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-weight: bold;
      padding: 20px 0;
    }
  }

  .button-box {
    text-align: center;
  }

  button {
    border: 0 !important;
    background-color: #a79100;
    color: #ffffff;
    border-radius: 3px;
    font-size: 1.2em;
    padding: 10px 15px;
    cursor: pointer;
  }
}

.item {
  width: 80%;
  margin: 30px auto;
}

.inp {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 50px 0;
  .label {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 16px;
    color: #b39e00;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }
  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: #fff;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }
  input {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px 0 6px 0;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #fff4ab;
    background: none;
    border-radius: 0;
    color: #544422;
    transition: all 0.15s ease;
    &:hover {
      background: rgba(#b1862a, 0.03);
    }
    &:not(:placeholder-shown) {
      + span {
        color: #5a667f;
        transform: translateY(-26px) scale(0.75);
      }
    }
    &:focus {
      background: none;
      outline: none;
      + span {
        color: #251c07;
        transform: translateY(-26px) scale(0.75);
      }
      + .border {
        transform: scaleX(1);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .login-wrapper {
    .login-box {
      width: 90%;
      height: 450px;

      .item {
        width: 90%;
      }
    }
  }
}
