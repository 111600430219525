@import 'styles/var.scss';
@media (prefers-color-scheme: light) {
  a {
    color: #242424;
  }

  .list {
    &:hover {
      background-color: #f6f6f6;
    }
  }

  .loading {
    background: #f6f7f8;
    background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(#f6f7f8),
      color-stop(0.2, #edeef1),
      color-stop(0.4, #f6f7f8),
      to(#f6f7f8)
    );
    background-image: -webkit-linear-gradient(
      left,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
  }
}
@media (prefers-color-scheme: dark) {
  a {
    color: #d7d6d6;
  }
  .list {
    &:hover {
      background-color: #1a1a1a;
    }
  }

  .loading {
    background: #2d2d2d;
    background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(#2d2d2d),
      color-stop(0.2, #505050),
      color-stop(0.4, #343434),
      to(#080808)
    );
    background-image: -webkit-linear-gradient(
      left,
      #2d2d2d 0%,
      #505050 20%,
      #343434 40%,
      #080808 100%
    );
  }
}

.main-wrapper {
  width: 100%;

  .list {
    display: block;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    padding: 30px 15px;
    margin-bottom: 10px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 0.8em;

      .tag {
        display: flex;
        align-items: center;

        .tag-title {
          font-size: 1.2em;
          font-weight: 900;
          opacity: 1;
          margin-right: 10px;
        }

        .action {
          margin: 0 3px;
          opacity: 0.5;
        }
      }

      .date {
        font-size: 1.2em;
        opacity: 0.5;
      }
    }

    .title {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      line-height: 2.5em;
      .text {
        font-size: 1.5em;
        border-bottom: 2px solid $mainColor;
        margin-bottom: 5px;
      }
    }

    .content {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;

      .text {
        font-size: 1.1em;
        line-height: 2em;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .main-wrapper {
    .list {
      padding: 15px 7px;
      margin-bottom: 5px;
    }
  }
}

.loading-list {
  height: 158.3px;
  margin-bottom: 10px;
}

.loading {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderSkeleton;
  animation-timing-function: linear;

  background-repeat: no-repeat;
  background-size: 3000px 104px;
  position: relative;
  border-radius: 5px;
}

.loading-tag-box {
  width: 100%;
  height: 15.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.loading-tag {
  height: 100%;
  width: 100px;
}

.loading-date {
  width: 68px;
  height: 100%;
}

.loading-header {
  margin-bottom: 5px;
  height: 37px;
  width: 69%;
}

.loading-desc {
  width: 80%;
  height: 31px;
}

@keyframes placeholderSkeleton {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.ad-list {
  height: 158px;
  padding: 0 !important;
}
