@import 'styles/var.scss';

.write-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    .write {
      width: 50%;
      height: 100%;
      background-color: #272822;
      overflow: auto;
      padding: 10px;

      textarea {
        width: 100%;
        height: 100%;
        border: 0px;
        background-color: transparent;
        resize: none;
        overflow: auto;
        color: #ffffff;
        font-size: 1.1em;
      }
    }

    .preview {
      width: 50%;
      padding: 10px;
      height: 100%;
      font-size: 1.1em;
      overflow: auto;
      word-break: break-all;
      overflow: auto;

      img {
        width: 100%;
      }
    }
  }
}

.header-wrapper {
  width: 100%;
  border: 7px solid $mainColor;
  .menu-wrapper {
    display: flex;
    align-items: center;
  }
  .menu-box {
    height: 60px;
    background-color: $mainColor;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
  }

  .publish-wrapper {
    background-color: #9f8a00;
    padding: 10px;
    cursor: pointer;
    margin: 0 10px;
    .publish {
      font-weight: bold;
      color: $mainColor;
      font-weight: bold;
      font-size: 1.25em;
    }
  }

  .menu {
    color: #ffffff;
    cursor: pointer;
    margin: 0 15px;
    font-size: 1.1em;
    font-weight: bold;
    opacity: 0.5;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    &.select {
      opacity: 1;
    }
  }
}

.content-box {
  max-height: 200px;
  width: 100%;
  overflow: auto;

  input,
  textarea {
    width: 100%;
    border: none !important;
    background-color: transparent;
    padding: 10px;
    font-size: 1.1em;
    background-color: #fff;
  }

  input {
    height: 50px;
  }

  textarea {
    resize: none;
    height: 100px;
  }
}

.tag-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100px;
  padding: 15px;
  .radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    margin: 10px;

    .label {
      position: relative;
      display: block;
      float: left;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border: 2px solid $gray;
      border-radius: 100%;
      -webkit-tap-highlight-color: transparent;

      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $mainColor;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
      }
    }
  }
}

input[type='radio']:not(:checked) {
  &:hover {
    + .label {
      &::after {
        transform: scale(3.6);
        opacity: 0.09;
      }
    }
  }
}
input[type='radio']:checked + .label {
  border-color: $mainColor;

  &:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.button-box {
  display: flex;
  align-items: center;
}

blockquote {
  margin: 0px !important;
  padding: 0.5em 40px;
}
