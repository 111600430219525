@import url('https://fonts.googleapis.com/css?family=Roboto:900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:400, 500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
@import 'styles/var.scss';

* {
  box-sizing: border-box;
}

body {
  font-size: 0px;
  font-family: 'Noto Sans KR', sans-serif;

  button {
    display: none;
  }

  input {
    display: none;
  }
}

#app {
  font-size: 14px;

  button {
    display: inline;
  }
}

a {
  text-decoration: none;
}

textarea,
input {
  border: 0;
  &:focus {
    outline: none !important;
  }
}

code {
  color: #ffad2e;
}

/* Light mode */
@media (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: #222222;
  }
}
/* Dark mode */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: #c8d1d9;
  }
}
