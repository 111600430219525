@import '../../styles/var.scss';

@media (prefers-color-scheme: light) {
  .section-title {
    div {
      color: #373737;
      &::after {
        background-color: $mainColor;
      }
    }
  }
  .point {
    background-color: $mainColor;
  }
}

@media (prefers-color-scheme: dark) {
  .section-title {
    div {
      color: #ffffff;
      &::after {
        background-color: $darkMainColor;
      }
    }
  }
  .point {
    background-color: $darkMainColor;
  }
}

.wrapper {
  a {
    color: $darkMainColor;
  }
}

.wrapper {
  width: 100%;
  max-width: 600px;
  font-family: 'Assistant', sans-serif;

  margin: auto;
  padding: 50px 0;

  .title-box {
    width: 300px;
    margin: auto;
  }

  .photo {
    width: 300px;
    margin: 60px auto 20px auto;
    position: relative;

    .point {
      width: 200px;
      height: 200px;
      position: absolute;
      right: -40px;
      top: -50px;
      z-index: -1;
      border-radius: 50%;
    }

    .line {
      width: 350px;
      height: 10px;
      background-color: #d3d3d3;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -175px;
    }

    img {
      width: 100%;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      font-size: 5em;
      font-weight: bold;
    }
  }

  .info {
    margin-top: 50px;
    text-align: center;
  }

  .name {
    font-size: 2.5em;
    font-weight: bold;
  }

  .sub {
    margin-top: 10px;
    font-size: 1.5em;
    opacity: 0.7;
  }

  .sub-desc {
    font-size: 1.2em;
    opacity: 0.5;
    margin-top: 7px;
    text-align: left;
  }

  .desc {
    margin-top: 50px;
    .desc-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      margin-bottom: 8px;

      .desc-title {
        width: 100px;
        margin-right: 10px;
        text-align: left;
      }

      .desc-value {
        width: 150px;
        text-align: left;
      }
    }
  }

  .section {
    margin: 100px 0;

    .section-title {
      font-size: 2.5em;
      margin-bottom: 50px;
      font-weight: bold;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 70%;
          max-width: 100px;
          height: 15px;
          position: absolute;
          right: -15px;
          bottom: 2px;
          z-index: -1;
        }
      }
    }

    .section-content {
      ul {
        margin-top: 0;
        padding-left: 20px;
        li {
          margin-bottom: 5px;
        }
      }
      .section-item {
        margin-bottom: 20px;
        font-size: 1.3em;

        .section-main-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0 !important;
          .date {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            opacity: 0.7;
            div {
              margin: 0 !important;
            }
          }
        }

        .section-sub-title {
          opacity: 0.6;
          font-style: italic;
          margin-bottom: 15px;
          font-size: 0.95em;
        }

        b {
          font-size: 1.1em;
        }

        &.mb-2 {
          margin-bottom: 50px;
        }
        div {
          margin: 5px 0;
        }
      }
    }
  }

  .section-desc-box {
    margin-top: 20px !important;
  }
  .section-desc {
    opacity: 0.7;
    display: flex;
    align-items: center;

    .section-desc-title {
      width: 70px;
      margin-right: 5px;
      font-size: 0.9em;
    }

    .section-desc-value {
      font-size: 0.9em;
    }
  }
}

.flag-box {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 250px;
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    padding: 0 30px;
  }
  .flag-box {
    flex-wrap: wrap;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .section-main-title {
    flex-direction: column !important;
  }

  .date {
    flex-direction: row !important;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px !important;
  }
  .section-sub-title {
    margin-bottom: 5px !important;
  }
}
