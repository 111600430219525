@import 'styles/var.scss';

@media (prefers-color-scheme: light) {
  .footer-wrapper {
    a {
      color: $mainColor;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .footer-wrapper {
    a {
      color: $darkMainColor;
    }
  }
}

.footer-wrapper {
  padding: 20px 0;
  display: flex;

  .oper-box {
    margin: 0 10px;

    a {
      padding: 0 5px;
      color: rgb(194, 194, 194);
    }
  }
}
