@import 'styles/var.scss';

/* Light mode */
@media (prefers-color-scheme: light) {
  .post-wrapper {
    a {
      color: #666666 !important;
      border-bottom: 2px solid $mainColor;
    }

    p,
    ul,
    ol {
      color: #666;
    }

    p,
    li {
      code {
        background: #f0f0f0;
        color: #444;
      }
    }
  }

  blockquote {
    background-color: #efefef;
    color: #636363;
  }
  .header {
    .post-desc {
      span {
        color: #b8b8b8;
      }
    }
  }

  .date {
    opacity: 0.5;
  }

  .loading {
    background: #f6f7f8;
    background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(#f6f7f8),
      color-stop(0.2, #edeef1),
      color-stop(0.4, #f6f7f8),
      to(#f6f7f8)
    );
    background-image: -webkit-linear-gradient(
      left,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .post-wrapper {
    a {
      color: #dddddd !important;
      border-bottom: 2px solid $darkMainColor;
    }

    p,
    ul,
    ol {
      color: #c9c9c9;
    }

    p,
    li {
      code {
        background: #454545;
        color: #ffffff;
      }
    }

    strong {
      color: #d2d2d2;
    }
  }

  blockquote {
    background-color: #222222;
    color: #636363;
  }
  .header {
    .post-desc {
      span {
        color: #7e7d7d;
      }
    }
  }
  .loading {
    background: #2d2d2d;
    background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(#2d2d2d),
      color-stop(0.2, #505050),
      color-stop(0.4, #343434),
      to(#080808)
    );
    background-image: -webkit-linear-gradient(
      left,
      #2d2d2d 0%,
      #505050 20%,
      #343434 40%,
      #080808 100%
    );
  }

  .date {
    opacity: 1;
  }

  li code {
  }
}

/* Common mode */
.post-wrapper {
  width: 100%;

  p,
  ul,
  ol {
    font-size: 15px;
    line-height: 28px;
  }
  li,
  p {
    code {
      font-family: 'Ubuntu', 'Noto Sans KR', sans-serif;
      font-size: 0.85em;
      padding: 2px 5px;
      font-weight: bold;
      border-radius: 3px;
    }
  }

  pre {
    border-radius: 7px;
    code {
      font-family: 'Ubuntu', sans-serif !important;
    }
  }

  .content {
    padding: 10px;
    font-size: 1em;
    line-height: 1.5em;
    word-break: break-all;
    img {
      display: block;
      margin: auto;
      max-width: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
}

pre {
  code {
    padding: 0 !important;
    font-weight: normal !important;
  }
}

blockquote {
  margin: 0px !important;
  padding: 0.5em 40px;
  border-radius: 7px;
}

.header {
  padding: 15px 0;
  margin-bottom: 40px;

  .desc-box {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    font-size: 1.2em;
    justify-content: space-between;
  }

  .date {
    font-size: 0.9em;
  }

  .post-title {
    font-size: 2.3em;
    word-break: break-all;
    font-weight: bold;
  }

  .post-desc {
    margin: 10px 0 3px 0;
    font-size: 1.3em;
    span {
      font-size: 0.8em;
    }
  }
}

@media screen and (max-width: 700px) {
  .post-wrapper {
    .content {
      padding: 0;
    }

    .post-title {
      font-size: 2em !important;
    }

    .post-desc {
      span {
        font-size: 0.9em !important;
      }
    }
    h1 {
      line-height: 1.5em;
    }

    img {
      max-width: 70% !important;
    }
  }
}

.comment {
  margin-top: 150px;
  margin-bottom: 100px;
}

.loading {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderSkeleton;
  animation-timing-function: linear;

  background-repeat: no-repeat;
  background-size: 3000px 104px;
  position: relative;
  border-radius: 5px;
}

@keyframes placeholderSkeleton {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

.loading-tag {
  width: 50px;
  height: 100%;
}

.loading-date {
  width: 100px;
  height: 100%;
}

.loading-title {
  width: 100%;
  height: 49px;
}

.loading-desc {
  width: 80%;
  height: 25px;
  margin: 8px 0 3px;
}

.loading-box-lg {
  height: 30px;
  width: 80%;
  margin: 15px 0;
}

.loading-box-sm {
  height: 30px;
  width: 30%;
  margin: 15px 0;
}

.loading-box-md {
  margin: 15px 0;
  height: 30px;
  width: 65%;
}
