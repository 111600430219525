.header-wrapper {
  padding: 20px 0;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .header-wrapper {
    padding: 10px 0;
    margin-bottom: 20px;
  }
}
