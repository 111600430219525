.template-wrapper {
  width: 80%;
  max-width: 950px;
  margin: auto;
  min-height: 100vh !important;
}

@media screen and (max-width: 700px) {
  .template-wrapper {
    width: 95%;
  }
}
