@import 'styles/var.scss';

@media (prefers-color-scheme: light) {
  .intro-wrapper {
    background-color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  .intro-wrapper {
    background-color: #121212;
  }
}
.intro-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 50px;
  font-weight: bold;

  font-family: 'Roboto';
  font-style: italic;
  &.hidden {
    animation: hidden 1s normal;
    animation-delay: 1.5s;
  }

  &.none {
    visibility: hidden;
  }

  :global {
    .dot {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: $mainColor;
      border-radius: 50%;
    }
  }
}

@keyframes hidden {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:global {
  .typed-cursor {
    display: none;
  }
}
