@import "styles/var.scss";

.confirm-wrapper {
  padding: 5px;
  .content {
    color: $mainColor;
    font-size: 1.3em;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .footer {
    text-align: right;
    .button {
      cursor: pointer;
      margin: 0 10px;
      opacity: 0.5;
      transition: all 0.3s;

      &:hover {
        opacity: 1;
      }

      &.confirm {
        color: rgb(196, 196, 196);
      }

      &.cancel {
        color: $mainColor;
      }
    }
  }
}
